/* .pedido-venda .desconto-button,
.container__products .desconto-button {
  display: inline-block;
  padding: 5px !important;
  height: 25px;
  width: 25px;
  background-color: #00dc25;
  border-radius: 3px;
  border: 0px;
  color: #fff;
  text-align: center;
  line-height: normal;
} */

#navbar_pedidoVenda ~nav {
  display: none;
}

.ReactTable .rt-tbody {
  overflow: hidden !important;
}

.pedido-venda-fieldList {
  border-radius: 5px;
  border: solid 1px #707070 !important;
  margin-top: 10px;
}

.pedido-venda-fieldList legend {
  padding: 0 6px;
  font-size: 11pt;
  width: auto;
  margin-left: 5px;
  margin-bottom: 0px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#content-pedidoVenda .content {
  min-height: 100vh;
  height: 100vh !important;
}

#content-pedidoVenda ~ footer ~nav {
  display: none;
}

.disabledColumn-variable {
  cursor: not-allowed;
}

.disabledColumn-variable h4 {
  pointer-events: none;
}

.disabledButton {
  pointer-events: none;
  opacity: 0.5;
}

.container__products {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 5px);
}

