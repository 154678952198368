.fieldList {
    border-radius: 5px;
    border: solid 1px #2b3553;
    margin-top: 10px;
  }
  
  .tabelaList {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    min-height: 80px;
  }
  
  .fieldList legend{
    padding: 0 6px;
    font-size: 11pt;
    width: auto;
    margin-left: 5px;
    margin-bottom: 0px;
  }
  
  .bigger{
    max-height: 290px;
    min-height: 160px;
  }
  
  .labelList {
    border-bottom: 1px solid #2b3553;
    width: 100%;
    margin-bottom: 0;
    padding: 5px;
  }
  
  .labelList:last-of-type {
    border-bottom: 0px;
  }
  
  .labelList:not(.labelListSelected):hover {
    background-color: rgb(239, 242, 245);
    cursor: pointer;
  }
  
  .labelListSelected {
    background-color: rgb(191, 219, 248);
    cursor: pointer;
  }
  
  .labelList:last-of-type:hover,
  .labelListSelected:last-of-type {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .labelListVazio {
    text-align: center;
    width: 100%;
    padding: 5px;
    margin-bottom: 0;
  }

  .fieldset-resultados {
    border: 1px solid;
    border-radius: 5px;
    padding: 0 10px 0 10px;
}

.fieldset-resultados legend {
    font-size: 16px;
    width: auto;
    margin: 0 30px 0 30px;
    padding: 0 15px 0 15px;
}

  