/*Inicio Switch*/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.7)
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider {
  background-color: #2196F3;
}

.switch input:disabled+.slider {
  background-color: rgba(206, 206, 206, 0.486);
}

.switch input:disabled+.slider:before {
  background-color: rgba(255, 255, 255, 0.432);
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-body {
  display: inline-block;
}

.switch-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center
}

.switch-row {
  display: flex;
  flex-direction: row;
}

.switch-column {
  display: flex;
  flex-direction: column;
}

.switch-text-top {
  align-self: center
}

.switch-text-left,
.switch-text-right {
  display: flex;
  align-items: center
}


/*Fim Switch*/


/*Inicio MiniMenu*/

.spn {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
}

.spn-lightMode {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border-color: #2e2e2e;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
}

.spn-active {
  border-color: #2e2e2e;
}

.spn-lightMode-active {
  border-color: #000
}

.spn:hover {
  border-color: #2e2e2e;
}

.spn-lightMode:hover {
  border-color: #ffffff;
}

.pass1 {
  width: 33.33%;
  height: 6px;
  background-color: none;
  position: absolute;
  border-radius: 4%;
}


/*Fim MiniMenu*/