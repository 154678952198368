.help-plugin {
  z-index: 1051;
  position: fixed;
  display: flex;
  right: 10px;
  top: 60px;
}

.help {
  display: flex;
}

.help .container {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  overflow: hidden;
  max-width: 1000px;
  min-width: 100px;
  min-height: 100px;
  position: relative;
}

.help .container .content {
  overflow: hidden;
  display: flex;
  text-align: justify;
}

.help-opened .container {
  transition: ease height 1s;
}

.help-closed .container {
  transition: ease height 0.5s;
}

.help-opened {
  transform: translateX(0) scaleY(1);
  transition: ease 0.5s;
}

.help-closed {
  transform: translateX(110%) scaleY(0) translateY(-150%);
  transition: ease 0.5s;
}

.help-opened .container .content {
  opacity: 1;
  height: 100%;
  transition: ease 1s;
  transition: ease height 1.5s;
}

.help-closed .container .content {
  opacity: 0;
  height: 0%;
  transform: translateX(-100%);
  transition: ease 0.4s;
}

.help .arrow {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid white;
}

.help-opened .arrow {
  margin-top: 45px;
  transition: ease 1s;
}

.help-closed .arrow {
  margin-top: 10px;
  transition: ease 0.1s;
}

.help .container .content .left {
  margin-right: 10px;
  width: 400px;
  height: 100%;
  align-items: center;
}

.help .container .content .video {
  margin-top: 10px;
  align-items: center;
}

.help .container .content .video p {
  color: rgb(13, 13, 49);
  font-weight: 550;
}

.help .not-found {
  display: flex;
  align-items: center;
}

.help .container .content .withoutimg {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.help p,
.help h1,
.help h2,
.help h3,
.help h4,
.help h5,
.help h6,
.help li {
  color: black;
}

.help a {
  font-weight: bolder;
  color: rgb(0, 104, 189);
}

.help-button {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  align-self: center;
}

.help-button .backgroud {
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  position: absolute;
  z-index: 1;
  margin: 0;
}

.help-button .help-icon {
  z-index: 2;
}
.help-button .help-icon:hover {
  z-index: 2;
  cursor: pointer;
}

.left-help-button {
  display: flex;
}

.btn-close {
  background-color: transparent;
  border: none;
  color: rgb(238, 98, 98);
  font-weight: 800;
  margin-top: 0px;
  height: 25px !important;
  position: absolute;
  right: 10px;
  top:5px;
  cursor: pointer;
}

.btn-close:hover,.btn-close:focus {
  transform: translateY(1px) scale(1.1);
}

.btn-close:focus {
  color: rgb(236, 159, 159);
  outline: 0px!important;
}
