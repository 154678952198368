/*Alterações no template*/
:root {
  --color-graph-weekly: linear-gradient(225deg, #1f8ef1 9.96%, #4b9de6 91.53%);
}

.wrapper.wrapper-full-page {
  height: 100% !important;
}

.full-page > .content {
  padding-bottom: 0px;
  padding-top: 100px;
}

.card.card-white .input-group-focus .input-group-prepend .input-group-text,
.card.card-white .input-group-focus .input-group-append .input-group-text,
.card.card-white .input-group-focus .form-control {
  border-color: #189ffa;
}

.card.card-white:not(.input-group-focus)
  .has-danger
  .input-group-prepend
  .input-group-text {
  border-color: #ec250d !important;
}

.card.card-white
  .input-group-focus:not(.has-danger)
  .input-group-prepend
  .input-group-text {
  border-color: #189ffa !important;
}

.card .card-white .form-control:focus {
  border-color: #189ffa;
}

.white-content .input-group-focus.has-danger .form-control:focus {
  border-color: #ec250d !important;
}

.form-control:focus .input-group-focus:not(.has-danger) {
  border-color: #189ffa !important;
}

.card .form-control:focus {
  border-color: #1d8cf8;
}

.white-content .input-group-focus.has-danger .form-control:focus {
  border-color: #ec250d !important;
}

.has-danger .form-control:focus {
  border-color: #ec250d;
}

button {
  height: 40px;
}

.form-control {
  height: 40px;
}

a {
  color: white;
}

a:hover,
a:focus {
  color: #189ffa;
}

.form-check input[type="checkbox"]:checked + .form-check-sign::before,
.form-check input[type="checkbox"]:checked + .form-check-sign::before {
  border: none;
  background-color: #15aa2e;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  border-color: #1d8cf8;
  background-color: #1d8cf8;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::before {
  border-color: #1d8cf8;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #151b2c;
  color: #979797;
}

.fileinput .thumbnail.img-circle {
  max-width: 150px;
}

.form-control .has-danger {
  border-color: #ec250d !important;
}

.btn:focus {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
}

.btn-link:hover {
  transform: translateY(-1px) !important;
  -webkit-transform: translateY(-1px) !important;
}


/*Fim Alterações no template*/

/*wizard*/


.card-wizard .card-body,
.card-wizard .card-footer {
  background-color: rgba(0, 0, 0, 0) !important;
  
}

.card-wizard .card-header {
  padding-top: 0px;
  padding-bottom: 0px;
}

.card-wizard .wizard-navigation {
  position: relative;
  margin: 160px auto 30px;
  
}

.card .card-wizard {
  margin-bottom: 0px;
}

.card-wizard .card-footer div:nth-child(2),
.card-wizard .card-footer div:first-child {
  padding-left: 0px;
  padding-right: 0px;
}

.card-wizard .card-footer div {
  float: initial !important;
}

.finishButtonMin {
  width: 100% !important;
}

.finishButtonRight {
  float: right;
}

.previousButtonMin {
  width: 100% !important;
}

.previousButtonRight {
  float: left;
}

.nextButtonMin {
  width: 100% !important;
}

.nextButtonRight {
  float: right;
}

.card-wizard .disabled {
  display: block;
}

.card-wizard .info-text {
  margin-bottom: 0px !important;
}

.card-wizard .visible {
  display:none;
}

/*Fim wizard*/

/*Sidebar*/

.navBar-Btn-minimizar {
  margin-top: 48px;
  margin-right: -35px !important;
  background-color: #1e1e2f;
  border: rgb(255, 255, 255) solid 2px;
  border-radius: 50%;
  padding: 2px;
  z-index: 100;
  color: black;
}

.navBar-Btn-minimizar .btn {
  padding: 10px;
}

.navBar-Btn-minimizar:hover {
  background-color: #1d8cf8;
}

.navBar-Btn-minimizar button {
  margin-top: -7px !important;
  margin: -6px;
}

.sidebar::before {
  content: none;
}

/*Fim Sidebar*/

/*Modal*/

.modal-content .form-control:focus {
  border-color: #1d8cf8;
  color: #222a42;
}

.modal-content .white-content .form-control:focus {
  border-color: #1d8cf8;
  color: #222a42;
}

.modal-content .form-control {
  color: #222a42;
}

body:not(.white-content).modal-content .table > tbody > tr > td,
.modal-content .table > thead > tr > th {
  color: #d1d1d1 !important;
  border-top: #e3e3e3 solid 0.8px;
}

.modal-content {
  background-color: white;
}

/*Fim Modal*/

/*Pagina de Login*/

.backgroundImg {
  position: absolute;
  z-index: 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  opacity: 0.4;
  background-size: 100% auto;
}

.background {
  width: 100%;
  height: 100%;
}

.background div {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
}

#conteudo {
  position: relative;
  width: 100%;
  height: 150px;
  margin: 0 auto;
  margin-top: -80px !important;
}

#circle {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  clip-path: circle(74px);
}

.bloco {
  width: 100%;
  height: 150px;
  position: absolute;
}

#borda {
  width: 195px;
  height: 195px;
  position: relative;
  border: 100px rgba(73, 72, 72, 1) solid;
  border-radius: 50%;
  margin: 0 auto;
}

#foto1 {
  width: 150px;
  height: 150px;
  position: relative;
  margin-left: 0px;
  transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
}

#foto2 {
  width: 150px;
  height: 150px;
  position: relative;
  margin-left: 100%;
  transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
}

#sombra {
  width: 148px;
  height: 148px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 5px 20px #444;
}

.foto {
  transition: 0.2s ease-in;
}

.transicaoLadoLadoIn {
  transition: 0.3s ease-in-out !important;
  position: absolute !important;
  opacity: 1;
  width: 100%;
  margin-left: 0%;
  visibility: visible;
}

.transicaoLadoLadoOut {
  transition: 0.3s ease-in-out !important;
  position: absolute !important;
  opacity: 0;
  width: 100%;
  margin-left: 100%;
  visibility: hidden;
}

.list ul {
  list-style: none;
  background-color: white;
  border-top: 1px solid rgba(29, 37, 59, 0.2);
  border: 1px solid rgba(29, 37, 59, 0.2);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0px;
  font-size: 14px;
}

.list li {
  height: 40px;
  color: black;
  margin: 0px;
}

.list button:hover {
  background-color: rgb(209, 227, 253);
  outline: thin dotted;
  outline: 0px auto;
  outline-offset: 0px;
}

.list button:focus {
  background-color: rgb(209, 227, 253);
  outline: thin dotted;
  outline: 0px auto;
  outline-offset: 0px;
}

.list button {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 5px;
  width: 100%;
  height: 100%;
  background-color: white;
  border: none;
  color: rgb(131, 131, 131);
  vertical-align: middle;
}

.divEmpresa {
  margin-top: -50px;
  width: 100%;
}

.divEmpresa h4 {
  transition: 2s ease-in;
  opacity: 0;
}

.tituloNovaSenha {
  transition: 1s ease-in;
  opacity: 0;
  margin-top: -50px;
  position: absolute;
}

/*Fim Pagina de Login*/

/*Novas classe*/

.btn {
  padding: 5px 10px;
}

.btn-padrao {
  min-width: 120px;
}

.btn-gp-padrao {
  padding: 5px 10px !important;
}

.error {
  color: #ec250d !important;
}

.btn-voltar-login {
  position: absolute;
  float: left;
  margin: -60px 0px 0px 10px;
  width: 38px !important;
  height: 38px !important;
}

.btn-voltar-login.btn.btn-simple:hover,
.btn-voltar-login.btn.btn-simple:focus {
  border-color: #1d8cf8;
}

/*Fim Novas classe*/

/*LoadingBar*/

.Loading__spinner___11Pm4 {
  position: fixed;
  top: 5px;
  left: 50% !important;
  z-index: 10002;
  pointer-events: none;
  transition: 350ms linear all;
}

/*Fim LoadingBar*/

/*React select*/

.css-w8afj7-Input {
  color: #1d8cf8 !important;
  padding-top: 6px !important;
  font-size: 0.75rem;
  max-width: 0px;
}

.css-26l3qy-menu {
  padding-top: 5px;
}

.react-select .react-select__control .react-select__indicators {
  width: 15px;
}

.react-select .react-select__control {
  height: 40px !important;
  border-radius: 7px !important;
}

.white-content .react-select .react-select__control {
  height: 40px !important;
  border-color: rgba(29, 37, 59, 0.5);
}

.react-select .react-select__menu {
  padding-top: 10px;
  max-height: 220px;
}

/*React select*/

/* Bar Wizard */

.card-wizard .nav-pills {
  background-color: #ddd !important;
  border-radius: 0.4285rem;
  height: 5px;
}

/* Bar Wizard */

.box {
  border-radius: 5px;
  border: solid 2px #2b3553;
  padding: 10px 15px 0px 15px;
  margin-top: 10px;
}

.white-content .box {
  border-radius: 5px;
  border: solid 2px #2b355379;
  padding: 10px 15px 0px 15px;
  margin-top: 10px;
}

.react-select {
  margin-bottom: 10px;
}

input {
  border-radius: 7px !important;
}

.input-group > input:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.modal.show .modal-dialog {
  transform: translate(0, 0px);
}

/* Mudanças no fronte */

body:not(.white-content) .card {
  background: #2e2e2e;
  box-shadow: 0px 1px 20px 0px rgba(26, 26, 26, 0.25);
}

body:not(.white-content) .form .card {
  background: #fff;
  box-shadow: 0px 1px 20px 0px rgba(26, 26, 26, 0.25);
}

/* BackGround do sistema */

.main-panel {
  background: rgb(30, 30, 30);
}

body:not(.white-content)
  .react-select.info
  .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value {
  color: white;
}

.btn-gp-padrao .active {
  background-image: linear-gradient(
    to bottom left,
    #031f33,
    #031f33,
    #031f33
  ) !important;
}

/* BORDA DO SELECT */

body:not(.white-content) .react-select .react-select__control {
  border: solid 0.5px #363b4a !important;
}

/* botoes inativa, editar e inativar */

.btn-icon.btn-link.btn.btn-success.btn-sm {
  background-color: transparent;
  background: transparent;
}

.btn-icon.btn-link.btn.btn-danger {
  background-color: transparent;
  background-image: none !important;
}

.btn .btn-link {
  background-color: transparent;
  background-image: none !important;
}


.botao-sem-estilo {
  color: #000;
  border: none;
  background: none;
  padding: 10px 20px;
  cursor: pointer;
}

.botao-sem-estilo:hover {
  box-shadow: none !important; /* Remove a sombra */
  background: transparent !important;
  background-color: transparent !important;
  background-image: none !important;
  border: none !important;;
  cursor: pointer;
  color: #ffb300;
  transition: color 0.3s ease; /* Animação suave */
}

/* fim dos botoes inativa, editar e inativar */

/*faz a troca de cor no icone principal da tela  cadastro de produtos/imagem */

.icon-principal {
  color: grey;
}

.icon-principal-active {
  color: yellow;
}

/*fim das classes que faz a troca de cor do icone principal*/

.sidebar-normal {
  color: black;
}

.tim-icons .icon-pencil {
  color: black;
}

.nav .tim-icons .icon-zoom-split {
  color: black;
}

.white-content
  .form-check-radio
  input[type="radio"]:checked
  + .form-check-sign::after {
  border-color: #1d8cf8;
  background-color: #1d8cf8;
}

.white-content
  .form-check-radio
  input[type="radio"]:checked
  + .form-check-sign::before {
  border-color: #1d8cf8;
}

/* footer */

.footer .copyright {
  color: white;
}

/* fim footer */

.card .btn-info .active {
  background-image: linear-gradient(
    to bottom left,
    #1d8cf8,
    #3358f4,
    #1d8cf8
  ) !important;
}

body:not(.white-content) .card .btn-group .btn:not(.active):not(.btn-success) {
  background-color: transparent;
  background-image: linear-gradient(
    to bottom left,
    #2b2b2b,
    #101010,
    #2b2b2b
  ) !important;
  background-size: 210% 210%;
}

.box {
  border: solid 2px #5e6680 !important;
}

.multi-select-input {
  border: solid 0.5px #363b4a !important;
}

.multi-select-focus .multi-select-input {
  border: solid 1px #656d8a !important;
}

.react-select .react-select__control:hover {
  border: solid 1px #5e6680 !important;
}

/* Modal modo dark */

.modal-body .form-control {
  border: solid 1px #6b6b6b !important;
  color: white !important;
  background-color: transparent;
}

.modal-body .form-control:focus {
  border: solid 1px #5e6680 !important;
}

.modal-content {
  background: #484848;
  color: rgb(255, 255, 255);
}

.modal-content .modal-header .title {
  color: rgb(255, 255, 255);
}

.modal-content .form-control:focus {
  border: solid px #656d8a !important;
}

/* Fim modal modo dark */

/* Modal modo light */

.white-content .modal-body .form-control {
  border: solid 1px #1d8cf8;
  color: black !important;
  background-color: transparent;
}

.white-content .modal-content .form-control:focus {
  border: solid 0.5px #1d8cf8 !important;
}

.white-content .modal-content {
  background: #fff;
  color: #000;
}

.white-content .modal-content .modal-header .title {
  color: rgb(0, 0, 0);
}

/* Fim modal modo light */

/* Correções do modo light */

.white-content .form-control {
  color: black;
  background: transparent;
}

.white-content .form-control:not(#login):not(#senha):focus {
  color: black;
  background: transparent;
  border: solid 0.5px #1d8cf8 !important;
}

/* Fim correções do modo light */

.modal-search .modal-dialog .form-control {
  color: white;
}

body:not(.white-content) .card-footer button:not(.group-btn-login).btn-default {
  background-image: linear-gradient(
    to bottom left,
    #4f4f4f,
    #101010,
    #4f4f4f
  ) !important;
}

body:not(.white-content) .grid-tributacoes-item {
  background-image: linear-gradient(
    to bottom left,
    #4f4f4f,
    #101010,
    #4f4f4f
  ) !important;
  color: white;
}

body:not(.white-content) .grid-tributacoes-add {
  background-image: linear-gradient(
    to bottom left,
    #4f4f4f,
    #101010,
    #4f4f4f
  ) !important;
  color: white;
}

body:not(.white-content)
  .card-body
  input:not(#login):not(#senha):not(#loginEsqueciSenha):not(.has-danger)
  .form-control {
  border: solid 1px #363b4a !important;
}

body:not(.white-content)
  .row
  input:not(#login):not(#senha):not(#loginEsqueciSenha).form-control:focus {
  border: solid 1px #5e6680 !important;
}

body:not(.white-content) .row .form-group .form-control {
  border: solid 1px #ec250d !important;
}

body:not(.white-content) .row .form-group .form-control:focus {
  border: solid 1px #ec250d !important;
}

body:not(.white-content) .form-group:not(.has-danger) .form-control {
  border: solid 1px #363b4a !important;
}

body:not(.white-content) .form-group:not(.has-danger) .form-control:focus {
  border: solid 1px #5e6680 !important;
}

body:not(.white-content)
  .form
  .card-footer
  button:not(.group-btn-login).btn-default {
  background-image: none !important;
}

.btn-group .btn.btn-success {
  background-image: linear-gradient(
    to bottom left,
    #00f2c3,
    #0098f0,
    #00f2c3
  ) !important;
}

/* paginação */

body:not(.white-content) .-pageInfo input {
  border: solid 1px #363b4a !important;
  color: rgb(0, 0, 0);
}

body:not(.white-content) .-pageInfo input:focus {
  border: solid 1px #5e6680 !important;
}

body:not(.white-content) .select-wrap.-pageSizeOptions select {
  border: solid 1px #363b4a !important;
  color: rgb(0, 0, 0);
}

body:not(.white-content) .select-wrap.-pageSizeOptions select:hover {
  border: solid 1px #5e6680 !important;
}

/* fim paginação */

body:not(.white-content) .modal-content .card-body .btn-default {
  background-image: linear-gradient(
    to bottom left,
    #2b2b2b,
    #101010,
    #2b2b2b
  ) !important;
}

body:not(.white-content) .form-control[disabled] {
  background-color: #424242;
  color: #c4c4c4;
}

body:not(.white-content) .btn-previous {
  background-image: linear-gradient(
    to bottom left,
    #2b2b2b,
    #101010,
    #2b2b2b
  ) !important;
}

.white-content .table > tbody > tr > td {
  color: black;
}

body:not(.white-content)
  .modal-content
  .modal-body
  .text-right
  button
  i::before {
  color: white !important;
}

/* edições da siderBar */

body:not(.sidebar-mini) .sideBarSubMenu {
  margin-left: 10% !important;
}

body:not(.sidebar-mini) .sideBarSubMenu {
  margin-left: 10% !important;
}

div.sidebar:hover .sideBarSubMenu {
  margin-left: 10% !important;
}

body:not(.sidebar-mini) .sideBarSubMenu li {
  position: relative;
  margin-left: -8%;
}

body:not(.sidebar-mini) .sideBarSubMenu li a {
  margin-left: -5%;
}

body:not(.sidebar-mini) .sidebar .collapse .nav a::before {
  margin-top: -3.2% !important;
  margin-left: 14% !important;
  left: 0% !important;
}

.sidebar-mini .collapse .sidebar .nav a::before {
  top: 16px !important;
  left: 1%;
}

.sidebar-mini .collapse .sidebar:hover .nav a::before {
  margin-top: -3.2% !important;
  margin-left: 15% !important;
  left: 0% !important;
}

.sidebar-mini .sideBarSubMenu .collapse.show .nav a {
  margin-left: 15% !important;
}

.sideBarSubMenu a p.textMenu {
  position: relative;
  margin-top: -3%;
}

.sidebar .nav .caret {
  top: 16px;
}

.sidebar-mini .sidebar .collapse .nav .active a::before {
  margin-top: -10%;
}

.sidebar-mini .sidebar:hover .collapse .nav .active a::before {
  margin-top: -2.3% !important;
  margin-left: 15% !important;
}

/*Fim edições da siderBar */

.ReactTable * {
  text-align: left;
}

.list-forne {
  border: solid 1px #6b6b6b !important;
}

.row-forne {
  border: solid 1px #6b6b6b !important;
}

.row-forne:hover {
  background-color: #383838;
}

.btn-greenDark {
  color: #ffffff;
  background-color: #036107;
  border-color: #036107;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
}

.btn-greenDark:hover {
  color: #ffffff;
  background-color: #036107 !important;
  border-color: #036107 !important;
  background-image: none !important;
}

.btn-greenDark:focus,
.btn-greenDark.focus {
  background-color: #036107 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 #013a04 !important;
  background-image: none !important;
}

.btn-greenDark.disabled,
.btn-greenDark:disabled {
  color: #ffffff;
  background-color: #036107 !important;
  border-color: #036107 !important;
  background-image: none !important;
}

.btn-greenDark:not(:disabled):not(.disabled):active,
.btn-greenDark:not(:disabled):not(.disabled).active,
.show > .btn-greenDark.dropdown-toggle {
  color: #ffffff;
  background-color: #036107 !important;
  border-color: #036107 !important;
  background-image: none !important;
}

.btn-greenDark:not(:disabled):not(.disabled):active:focus,
.btn-greenDark:not(:disabled):not(.disabled).active:focus,
.show > .btn-greenDark.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(255, 179, 0, 0.5) !important;
  background-image: none !important;
}



.btn-yellow {
  color: #ffffff;
  background-color: #ffb300;
  border-color: #ffb300;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
}

.btn-yellow:hover {
  color: #ffffff;
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
  background-image: none !important;
}

.btn-yellow:focus,
.btn-yellow.focus {
  background-color: #ffb300 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(255, 179, 0, 0.5) !important;
  background-image: none !important;
}

.btn-yellow.disabled,
.btn-yellow:disabled {
  color: #ffffff;
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
  background-image: none !important;
}

.btn-yellow:not(:disabled):not(.disabled):active,
.btn-yellow:not(:disabled):not(.disabled).active,
.show > .btn-yellow.dropdown-toggle {
  color: #ffffff;
  background-color: #ffb300 !important;
  border-color: rgb(255, 193, 49) !important;
  background-image: none !important;
}

.btn-yellow:not(:disabled):not(.disabled):active:focus,
.btn-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(255, 179, 0, 0.5) !important;
  background-image: none !important;
}

.btn-green {
  color: #ffffff;
  background-color: #00dc25;
  border-color: #00dc25;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
}

.btn-green:hover {
  color: #ffffff;
  background-color: #00dc25 !important;
  border-color: #00dc25 !important;
  background-image: none !important;
}

.btn-green:focus,
.btn-green.focus {
  background-color: #00dc25 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(0, 220, 37, 0.5) !important;
  background-image: none !important;
}

.btn-green.disabled,
.btn-green:disabled {
  color: #ffffff;
  background-color: #00dc25 !important;
  border-color: #00dc25 !important;
  background-image: none !important;
}

.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
  color: #ffffff;
  background-color: #00dc25 !important;
  border-color: rgb(50, 245, 82) !important;
  background-image: none !important;
}

.btn-green:not(:disabled):not(.disabled):active:focus,
.btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(0, 220, 37, 0.5) !important;
  background-image: none !important;
}

.btn-red {
  color: #ffffff;
  background-color: #ff0000;
  border-color: #ff0000;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
}

.btn-red:hover {
  color: #ffffff;
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  background-image: none !important;
}

.btn-red:focus,
.btn-red.focus {
  background-color: #ff0000 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(0, 220, 37, 0.5) !important;
  background-image: none !important;
}

.btn-red.disabled,
.btn-red:disabled {
  color: #ffffff;
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  background-image: none !important;
}

.btn-red:not(:disabled):not(.disabled):active,
.btn-red:not(:disabled):not(.disabled).active,
.show > .btn-red.dropdown-toggle {
  color: #ffffff;
  background-color: #ff0000 !important;
  border-color: rgb(245, 50, 50) !important;
  background-image: none !important;
}

.btn-red:not(:disabled):not(.disabled):active:focus,
.btn-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-red.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(220, 0, 0, 0.5) !important;
  background-image: none !important;
}

.content {
  padding-top: 70px;
  padding-bottom: 0px !important;
}

.footer {
  padding-top: 5px !important;
}

.disabled-pointer .form-check .form-check-sign::after {
  cursor: no-drop !important;
}

.disabled-pointer .form-check .form-check-label {
  cursor: no-drop;
}

.text-left {
  padding-top: 14px;
}

/* Mundanças no select quando focado*/

body:not(.white-content)
  .react-select.info
  .react-select__control.react-select__control--is-focused {
  border: solid 1px #5e6680 !important;
}

.react-select.info .react-select__control.react-select__control--is-focused {
  border: solid 1px #0098f0 !important;
}

.contribuinte {
  margin-bottom: 0 !important;
}

body:not(.white-content)
  .react-select.react-select--is-disabled
  .react-select__control--is-disabled {
  background-color: #424242;
}

body.white-content
  .react-select.react-select--is-disabled
  .react-select__control--is-disabled {
  background-color: #e3e3e3;
}

body.white-content
  .fieldList
  .tabelaList
  .labelList:not(.labelListSelected):hover {
  background-color: rgb(242, 242, 242) !important;
}

.fieldList .tabelaList .labelList:not(.labelListSelected):hover {
  background-color: rgb(68, 68, 68) !important;
}

.fieldList .tabelaList .labelListSelected {
  background-color: rgba(71, 179, 255, 0.41) !important;
}

.inputRedBorder {
  border: 1px solid red !important;
  color: white !important;
  background-color: transparent;
  height: 40px;
  font-size: 0.75rem;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  display: block;
  width: 100%;
  padding: 0.5rem 0.7rem;
  line-height: 1.42857;
  background-clip: padding-box;
  box-shadow: none;
}

.inputRedBorder:focus {
  border: 1px solid red !important;
  outline: 0 !important;
}

.card-stats hr {
  border-color: #9a9a9a !important;
}

.scrollBarPedido {
  max-height: 38em;
  overflow: auto;
}

.scrollBarPedido::-webkit-scrollbar {
  width: 5px;
}

.scrollBarPedido::-webkit-scrollbar-track {
  background: transparent;
}

.scrollBarPedido::-webkit-scrollbar-thumb {
  background: #aaa7aa;
}

.scrollBarPedido::-webkit-scrollbar-thumb:hover {
  width: 10px;
}


.scrollBar {
  max-height: 22em;
  overflow: auto;
}

.scrollBar::-webkit-scrollbar {
  width: 5px;
}

.scrollBar::-webkit-scrollbar-track {
  background: transparent;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: #aaa7aa;
}

.scrollBar::-webkit-scrollbar-thumb:hover {
  width: 10px;
}

.informationInit input {
  border: solid 1px #363b4a !important;
}

.rowCenterItens {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ver-cadastro {
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
}

.alert.alert-warning {
  background-color: #cac036 !important;
  color: #eee8e8 !important;
}

.btn.btn {
  font-weight: normal;
}

.remove-margin-bottom {
  margin-bottom: 0 !important;
}

/* Yellow mode */

body.yellow-content .card {
  background-color: #fff296;
  color: #000;
}

body.yellow-content .main-panel.ps {
  background-color: #dedec7;
  color: #000;
}

body.yellow-content .form-control {
  background-color: transparent;
  color: #000;
}

body.yellow-content .form-control[disabled] {
  background-color: #e6d67f;
  color: #000;
}

body.yellow-content label {
  color: #000;
}

body.yellow-content .ReactTable .rt-td {
  color: #000 !important;
}

body.yellow-content .ReactTable .rt-thead .rt-th {
  color: #000;
}

body.yellow-content .footer .copyright {
  color: #000;
}

body.yellow-content .card .card-body h5 {
  color: #000 !important;
}

body.yellow-content .modal-content {
  background-color: #cccc55;
}

body.yellow-content fieldset {
  color: #000;
}

body.yellow-content .modal-content .modal-header .title {
  color: #000;
}

body.yellow-content .modal-content .modal-body h4 {
  color: #000;
}

body.yellow-content .modal .modal-header .close {
  color: #cf0045;
}

body:not(.white-content)
  .card-chart.card
  .card-header
  .btn-simple.btn.btn-info.btn-sm:not(.active) {
  background-image: none !important;
}

body.yellow-content .info-text {
  color: #000;
}

/*mudanças na dashboard para yellow-content*/

body.yellow-content .card-stats .numbers .card-category {
  color: #000;
}

body.yellow-content .card-body .numbers h3 {
  color: #000;
}

body.yellow-content .card .card-header .card-title {
  color: #000;
}

body.yellow-content .card .card-header .card-category {
  color: #000;
}

body.yellow-content
  .card-chart.card
  .card-header
  .btn-simple.btn.btn-info.btn-sm:not(.active) {
  background-image: none !important;
  border-color: #000;
  color: #000;
}

body.yellow-content .switch-text-left .text-warning .text-center {
  color: #000;
}

body.yellow-content
  .react-select.info
  .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value {
  color: #000 !important;
}

body.yellow-content .multi-select-input {
  color: #000 !important;
}

/*Select desativado*/

body.yellow-content:not(.white-content)
  .react-select.react-select--is-disabled
  .react-select__control--is-disabled {
  background-image: none !important;
  background-color: rgb(204, 204, 85);
}

body.yellow-content .card-wizard .nav-pills .nav-item .nav-link p {
  color: #000;
}

body.yellow-content .tab-content .tab-pane.fade.show.active .form-group div {
  color: #000;
}

body.yellow-content .card h4 {
  color: #000 !important;
}

body.yellow-content .ReactTable .-pagination .-center {
  color: #000;
}

body.yellow-content
  .ReactTable
  .-pagination
  .-center
  .select-wrap
  .-pageSizeOptions
  select {
  color: #000 !important;
}

body.yellow-content .info-text {
  color: #000;
}
.ReactTable .-pagination .-pageJump {
  color: #000;
}

/* Gray mode */

body.gray-content .card {
  background-color: #919191;
  color: #000;
}

body.gray-content .main-panel.ps {
  background-color: #e3e3e3;
  color: #000;
}

body.gray-content .form-control {
  background-color: transparent;
  color: #000;
}

body.gray-content .form-control[disabled] {
  background-color: #ababab;
  color: #000;
}

body.gray-content label {
  color: #fff;
}

body.gray-content .ReactTable .rt-td {
  color: #000 !important;
}

body.gray-content .ReactTable .rt-thead .rt-th {
  color: #000;
}

body.gray-content .footer .copyright {
  color: #000;
}

body.gray-content .modal-content {
  background-color: #cccc55;
}

body.gray-content fieldset {
  color: #000;
}

body.gray-content .modal-content .modal-header .title {
  color: #fff;
}

body.gray-content .modal-content .modal-body h4 {
  color: #000;
}

body.gray-content .modal .modal-header .close {
  color: #cf0045;
}

body:not(.white-content)
  .card-chart.card
  .card-header
  .btn-simple.btn.btn-info.btn-sm:not(.active) {
  background-image: none !important;
}

body.gray-content .info-text {
  color: #000;
}

body.gray-content .modal-content {
  background-color: #8c8c8c;
}

body.gray-content fieldset {
  color: #000;
}

body.gray-content .modal-content .modal-header .title {
  color: #fff;
}

body.gray-content .modal-content .modal-body h4 {
  color: #000;
}

body.gray-content .modal .modal-header .close {
  color: #cf0045;
}

body:not(.white-content)
  .card-chart.card
  .card-header
  .btn-simple.btn.btn-info.btn-sm:not(.active) {
  background-image: none !important;
}

.btn-yellow:not(:disabled):not(.disabled):active,
.btn-yellow:not(:disabled):not(.disabled).active,
.show > .btn-yellow.dropdown-toggle {
  color: #ffffff;
  background-color: #ffb300 !important;
  border-color: white !important;
  background-image: none !important;
}

/*mudanças na dashboard para gray-content*/

body.gray-content .card-stats .numbers .card-category {
  color: #000;
}

body.gray-content .card-body .numbers h3 {
  color: #000;
}

body.gray-content .card .card-header .card-title {
  color: #fff;
}

body.gray-content .card .card-header .card-category {
  color: #000;
}

body.gray-content
  .card-chart.card
  .card-header
  .btn-simple.btn.btn-info.btn-sm:not(.active) {
  background-image: none !important;
  border-color: #000;
  color: #000;
}

body.gray-content .switch-text-left .text-warning .text-center {
  color: #000;
}

body.gray-content
  .react-select.info
  .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value {
  color: #000 !important;
}

body.gray-content .multi-select-input {
  color: #000 !important;
}

/*Select desativado*/

body.gray-content:not(.white-content)
  .react-select.react-select--is-disabled
  .react-select__control--is-disabled {
  background-image: none !important;
  background-color: #ababab;
}
body.gray-content .viewSet {
  border-color: #000 !important;
}
body.gray-content .lengendViews {
  color: #000 !important;
}

body.gray-content .card-wizard .nav-pills .nav-item .nav-link p {
  color: #000;
}

body.gray-content .tab-content .tab-pane.fade.show.active .form-group div {
  color: #000;
}

body.gray-content .card h4 {
  color: #000 !important;
}

body.blue-content body.search-bar.input-group i {
  color: #000;
}

/* blue mode */

body.blue-content .card {
  background-color: #b4d8e4;
  color: #000;
}

body.blue-content .main-panel.ps {
  background-color: #ddffff;
  color: #000;
}

body.blue-content .form-control {
  background-color: transparent;
  color: #000;
}

body.blue-content .form-control[disabled] {
  background-color: #8aacb8;
  color: #000;
}

body.blue-content label {
  color: #000;
}

body.blue-content .ReactTable .rt-td {
  color: #000 !important;
}

body.blue-content .ReactTable .rt-thead .rt-th {
  color: #000;
}

body.blue-content .footer .copyright {
  color: #000;
}

body.blue-content .modal-content {
  background-color: #b4d4de;
}

body.blue-content fieldset {
  color: #000;
}

body.blue-content .modal-content .modal-header .title {
  color: #fff;
}

body.blue-content .modal-content .modal-body h4 {
  color: #000;
}

body.blue-content .modal .modal-header .close {
  color: #cf0045;
}

body.blue-content fieldset {
  color: #000;
}

body.blue-content .modal-content .modal-header .title {
  color: #000;
}

body.blue-content .modal-content .modal-body h4 {
  color: #000;
}

body.blue-content .modal .modal-header .close {
  color: #cf0045;
}

body:not(.white-content)
  .card-chart.card
  .card-header
  .btn-simple.btn.btn-info.btn-sm:not(.active) {
  background-image: none !important;
}

body.blue-content .info-text {
  color: #000;
}

body.blue-content .modal-content {
  background-color: #b4d4de;
}

body.blue-content fieldset {
  color: #000;
}

body.blue-content .modal-content .modal-header .title {
  color: #000;
}

body.blue-content .modal-content .modal-body h4 {
  color: #000;
}

body.blue-content .modal .modal-header .close {
  color: #cf0045;
}

/*mudanças na dashboard para blue-content*/

body.blue-content .card-stats .numbers .card-category {
  color: #000;
}

body.blue-content .card-body .numbers h3 {
  color: #000;
}

body.blue-content .card .card-header .card-title {
  color: #000;
}

body.blue-content .card .card-header .card-category {
  color: #000;
}

body.blue-content
  .card-chart.card
  .card-header
  .btn-simple.btn.btn-info.btn-sm:not(.active) {
  background-image: none !important;
  border-color: #000;
  color: #000;
}

body.blue-content .switch-text-left .text-warning .text-center {
  color: #000;
}

body.blue-content
  .react-select.info
  .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value {
  color: #000 !important;
}

body.blue-content .multi-select-input {
  color: #000 !important;
}

body.blue-content .ReactTable .-pagination .-pageInfo {
  color: #000 !important;
}

/*Select desativado*/

body.blue-content:not(.white-content)
  .react-select.react-select--is-disabled
  .react-select__control--is-disabled {
  background-image: none !important;
  background-color: rgb(138 172 184);
}

body.blue-content .card-wizard .nav-pills .nav-item .nav-link p {
  color: #000;
}

body.blue-content .tab-content .tab-pane.fade.show.active .form-group div {
  color: #000;
}

body.blue-content .card h4 {
  color: #000 !important;
}

body.blue-content body.search-bar.input-group i {
  color: #000;
}

.span-mode-light {
  border-color: #fff;
  color: #000;
}

.fieldset-style {
  border: 2px solid rgb(94, 102, 128);
  border-radius: 5px;
  padding: 2%;
}

.fieldset-legends-style {
  font-size: 13px;
  width: auto;
  margin: 0px 15px;
  padding: 0px 15px;
}

.help-button-cliente {
    padding: 0;
    margin-top: 1%;
    margin-right: 22%;
}

.padding-padrao-col{
    padding: 2px;
}

.padding-padrao-row{
    padding-left: 16px;
    padding-right: 16px;
}

.white-content #modalFaturamento ul li a {
  color: #fff !important;
}

.white-content #body-miniCard button label {
  color: #fff !important;
}

.white-content #modal-lancamentoTitulos #dados-pagamento #input-total,
.yellow-content #modal-lancamentoTitulos #dados-pagamento #input-total,
.gray-content #modal-lancamentoTitulos #dados-pagamento #input-total,
.blue-content #modal-lancamentoTitulos #dados-pagamento #input-total {
  color: rgb(41 253 41) !important
}

.white-content #modal-lancamentoTitulos #dados-pagamento #input-restante,
.yellow-content #modal-lancamentoTitulos #dados-pagamento #input-restante,
.gray-content #modal-lancamentoTitulos #dados-pagamento #input-restante,
.blue-content #modal-lancamentoTitulos #dados-pagamento #input-restante {
  color: rgb(255 7 7) !important
}

.white-content #paginationComponent input,
.yellow-content #paginationComponent input {
  border: 1px solid #333 !important;
}

.white-content #paginationComponent span,
.yellow-content #paginationComponent span {
  color: #333 !important;
}

.white-content #searchOrder-container h3,
.white-content #searchOrder-container .details-orderIcon,
.yellow-content #searchOrder-container h3,
.yellow-content #searchOrder-container .details-orderIcon {
  color: rgb(93 92 92) !important;
}

.yellow-content #modalInfoOrder .detailsOrder-inputs div:last-child input {
  color: rgb(20 189 20) !important;
}

.white-content #modalInfoOrder .detailsTable-itens svg {
  color: gray !important;
}

.blue-content #paginationComponent .amount-page .select-pageSize,
.gray-content #paginationComponent .amount-page .select-pageSize,
.yellow-content #paginationComponent .amount-page .select-pageSize,
.white-content #paginationComponent .amount-page .select-pageSize {
  color: #000 !important;
  border: 1px solid rgb(43 53 83) !important;
}

.white-content .table-recibemento .btn-icon i.tim-icons,
.yellow-content .table-recibemento .btn-icon i.tim-icons {
  color: #333 !important;
}

.input-number-noArrowsinput::-webkit-outer-spin-button,
.input-number-noArrowsinput::-webkit-inner-spin-button {
    -webkit-appearance: none;
}