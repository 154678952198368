.spinner{
  width: 60px;
  height: 60px;
  border: 8px solid rgba(0, 0, 0, .1);
  border-left-color: rgba(0, 153, 255, 0.668);
  z-index: 1000;
  border-radius: 50%;
  animation: spin .7s linear infinite;
}

@keyframes spin {
  to {transform: rotate(360deg);}
}